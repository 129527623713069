import React, { useState, useEffect } from "react";
import "./App.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import edbaImage from "./edba.png";

function App() {
  const [formData, setFormData] = useState({
    name: "",
    rollno: "",
    email: "",
    div: "",
    course: "",
    group: "",
  });

  const [errors, setErrors] = useState({});
  const [courses, setCourses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [loading, setLoading] = useState(false); // State for loader

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "https://edba-admissions-backend.teradoengineering.com/get-course"
        );
        if (response.data.errCode === -1) {
          setCourses(response.data.data);
        } else {
          console.error("Error fetching courses:", response.data.errMsg);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  const handleChange = (e) => {
    const { id, name, value } = e.target;
    const capitalizedValue = value.toUpperCase();
    if (id == "div") {
      setFormData((prevData) => ({
        ...prevData,
        [id || name]: capitalizedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id || name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id || name]: "",
    }));
  };

  const handleCourseChange = (e) => {
    const selectedCourse = e.target.value;
    const course = courses.find((c) => c.course === selectedCourse);
    setGroups(course ? course.groups : []);
    setFormData((prevData) => ({
      ...prevData,
      course: selectedCourse,
      group: "",
    }));
  };

  const validate = () => {
    
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "This field is required.";
    tempErrors.rollno = formData.rollno ? "" : "This field is required.";
    tempErrors.email = formData.email ? "" : "This field is required.";
    tempErrors.div = formData.div ? "" : "This field is required.";
    tempErrors.course = formData.course ? "" : "This field is required.";
    tempErrors.group = formData.group ? "" : "This field is required.";

    setErrors(tempErrors);
    return Object.values(tempErrors).every((error) => error === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true); // Start loading
      try {
        const response = await axios.post(
          "https://edba-admissions-backend.teradoengineering.com/add-student",
          formData
        );
        if (response.data.errCode === 2) {
          setDialogType("seatsFull");
          setOpen(true);
        } else if (response.data.errCode === 1) {
          setDialogType("error");
          setOpen(true);
        } else if (response.data.errCode === 3) {
          setDialogType("emailExists");
          setOpen(true);
        } else {
          setDialogType("success");
          setOpen(true);
          console.log("Form submitted successfully:", response.data);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className="App"
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
      }}
    >
      <header
        className="App-header"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
            backgroundColor: "#fff",
            padding: 2,
            borderRadius: 2,
            boxShadow: 3,
            display: "flex",
            width: "110%",
            flexDirection: "column",
            alignItems: "center",
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <img
            src={edbaImage}
            alt="Form Image"
            style={{ width: "100px", height: "100px", marginBottom: "16px" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              margin: "0px",
            }}
          >
            <TextField
              required
              id="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              sx={{ width: "100%", maxWidth: "600px" }} // Increased width for mobile
            />
            <TextField
              required
              id="rollno"
              type="number"
              label="Roll no (Previous Year)"
              value={formData.rollno}
              onChange={handleChange}
              error={!!errors.rollno}
              helperText={errors.rollno}
              sx={{ width: "100%" }} // Increased width for mobile
            />
            <TextField
              required
              id="email"
              label="Email (Email Registered on Edba)"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ width: "100%" }}
            />
            <TextField
              required
              id="div"
              label="Div (Previous Year)"
              value={formData.div}
              onChange={handleChange}
              error={!!errors.div}
              helperText={errors.div}
              inputProps={{ maxLength: 1 }}
              sx={{ width: "100%" }} // Limit input to one character
            />

            <Select
              required
              id="course"
              name="course"
              value={formData.course}
              onChange={handleCourseChange}
              displayEmpty
              className="responsive-select"
              sx={{
                m: 1,
                textAlign: "left",
                ".MuiSelect-select": { textAlign: "left" },
              }}
              error={!!errors.course}
            >
              <MenuItem value="" disabled>
                Select Course
              </MenuItem>
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.course}>
                  {course.course.toUpperCase()}
                </MenuItem>
              ))}
            </Select>

            <Select
              required
              id="group"
              name="group"
              value={formData.group}
              onChange={handleChange}
              displayEmpty
              className="responsive-select"
              sx={{
                m: 1,
                textAlign: "left",
                ".MuiSelect-select": { textAlign: "left" },
              }}
              disabled={!formData.course}
              error={!!errors.group}
            >
              <MenuItem value="" disabled>
                Select Group
              </MenuItem>
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.group}>
                  {group.group.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "16px", width: "25ch", position: "relative" }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Box>
      </header>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogType === "seatsFull"
            ? "Seats Full"
            : dialogType === "emailExists"
            ? "Email Already Exists"
            : dialogType === "error"
            ? "Error"
            : "Application Submitted"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogType === "seatsFull"
              ? "The seat is full, so you can't apply. Please try a different course."
              : dialogType === "emailExists"
              ? "You cannot submit the form more than once or for multiple courses."
              : dialogType === "error"
              ? "An error occurred while submitting your application. Please try again."
              : "Your application has been submitted successfully."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
